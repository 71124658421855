import React, { useEffect, useState } from "react";
import "./styles.less";
import axios from "axios";

export default props => {
    const [cfg, setCfg] = useState({});

    const link = [
        {
            title: "热门产品",
            children: [
                {
                    title: "联合征信",
                    link: {
                        dev: "http://lhzx.dev.linkfin.caih.local/",
                        test: "http://lhzx.test.linkfin.caih.local/",
                        uat: "http://lhzx.uat.linkfin.caih.local/",
                        pro: "https://lhzx.linkfinance.cn/"
                    }
                },
                {
                    title: "保理云平台",
                    link: "/product/bly"
                },
                {
                    title: "薪智捷",
                    link: "/product/xzj"
                }
                // { title: "工薪贷", link: "" }
            ]
        },
        {
            title: "快速入口",
            children: [
                {
                    title: "工作台",
                    link: "/admin/qk"
                },
                // { title: "账号管理  ", link: "" },
                { title: "解决方案", link: "/solution" }
            ]
        },
        {
            title: "支持与服务",
            children: [
                // { title: "关于我们", link: "" },
                { title: "联系我们", link: "/product/contact" }
                // { title: "新闻公告", link: "" },
                // { title: "帮助中心", link: "" }
            ]
        }
        // {
        //     title: "更多推荐",
        //     children: [
        //         { title: "华建通", link: "" },
        //         { title: "电子口岸", link: "" }
        //     ]
        // },
        // {
        //     title: "条款",
        //     children: [
        //         { title: "服务协议", link: "" },
        //         { title: "隐私政策", link: "" },
        //         { title: "免责声明", link: "" }
        //     ]
        // }
    ];

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("/api/sys/cfg");
            setCfg(data);
        })();
    }, []);
    return (
        <div styleName='footer-wrap'>
            <div styleName='top'>
                {link.map((v, i) => {
                    return (
                        <div styleName='group' key={i}>
                            <p styleName='title'>{v.title}</p>
                            {v.children.map((c, i) => {
                                return (
                                    <a
                                        key={i}
                                        styleName='link'
                                        // target='_blank'
                                        // href={c.link?.toString() == "[object Object]" ? c.link[cfg.ENV || "dev"] : c.link}
                                    >
                                        {c.title}
                                    </a>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div styleName='bottom'>
                <div styleName='r-1'>地址： 中国广西南宁市良庆区秋月路18号，530200</div>
                <div styleName='r-2'>Copyright 中国—东盟信息港股份有限公司 All Rihgts Reserved</div>
                <div styleName='r-3'>
                    <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>
                        桂ICP备16005301号-2
                    </a>
                    <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010802000178' target='_blank'>
                        桂公网安备 45010802000178号
                    </a>
                </div>
            </div>
        </div>
    );
};
