import React from "react";
import PortalMenu from "./../portalMenu";
import { message } from "antd";
import "./index.less";

message.config({
    getContainer: () => {
        return document.querySelector("#mh-header");
    }
});

const Header = props => {
    const { mobile, financial, user_status, mh_sel } = props.userInfo || {};

    let link = financial || !user_status?.comps.length || mh_sel ? "/admin/qk" : "/loginTp";

    if (!user_status?.user_status) {
        link = "/result?resTp=7";
    }

    return (
        <div styleName='header-warp'>
            <div styleName='header-container'>
                <div styleName='left'>
                    <img
                        // onClick={() => {
                        //     location.href = "/";
                        // }}
                        style={{ cursor: "pointer" }}
                        src={require("@assets/mh/logo.png")}
                        alt=''
                    />
                </div>
                <div styleName='right'>
                </div>
            </div>
        </div>
    );
};

export default Header;
